import React, { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router'
import { confirmForgotPasswordEmail } from '../../redux/actions'
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { connect } from 'react-redux'
import FormTextField from '../../components/FormTextField'
import { reduxForm, change } from 'redux-form'
import { required, passwordStrength, email } from '../../utilities/validators'
import { passwordMatch } from '../../utilities/validators'
import * as S from '../../utilities/StringConstants'
import '../../styles/index.scss'
import { injectIntl, intlShape } from 'react-intl'
import queryString from 'query-string'
import Container from '@material-ui/core/Container'
import FormPassword from '../../components/FormPassword'
import WelcomeLayout from "../welcome/welcomeLayout";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	input: {
		color: "#fff",
		fontSize: "1.7rem",
	},
	returnBackLink: (props) => ({
		textDecoration: 'none',
		fontSize: '13px',
		fontWeight: '400',
		textTransform: 'uppercase',
		display: 'table',
		letterSpacing: '1.28px',
		margin: '0 0 20px',
		color: props.uiType != 1 ? '#fff' : '#000',
		'&:hover, &:focus': {
		  color: props.uiType != 1 ? '#fff' : '#000',
		  textDecoration: 'none',
		},
	  }),
}));

const emailValidator = email
const requiredValidator = required
const passwordStrengthValidator = passwordStrength
const passwordMatchValidator = passwordMatch('password')

const ConfirmForgotPassword = ({
	submitRequest,
	handleSubmit,
	intl,
	changeCode,
	changeEmail,
	location,
	history
}) => {
	const theme = useTheme()
	const [ isEmailPrepopulated, setIsEmailPrepopulated ] = useState(false)
	const [ isCodePrepopulated, setIsCodePrepopulated ] = useState(false)
	const [ uiType, setUiType ] = useState(0)

	const classes = useStyles({ uiType });

	useEffect(() => {
		let params = queryString.parse(location.search)
		if (params.code) {
			changeCode(params.code)
			setIsCodePrepopulated(true)
		}
		if (params.email) {
			changeEmail(params.email)
			setIsEmailPrepopulated(true)
		}
		if (params && params.ui) {
			setUiType(Number(params.ui));
		}
	}, [])

	const onSubmitForm = (values) => {
		submitRequest(values.email, values.code, values.password, uiType)
	}
	return (
		<WelcomeLayout
		intl={intl}
		headerTextId="app.login.headerText"
		welcomeMessageId="app.login.welcomeMessageText"
		contactUsId="app.login.contactUsText"
		contactUsLinkId="app.login.contactUsLinkText"
		uiType={uiType}
	  >
		<form
			data-testid="form-confirm-forgot-password"
			onSubmit={handleSubmit(onSubmitForm)}
		>
				<Grid
					container
					direction="column"
					alignItems="center"
					justifyContent="center"
					spacing={8}
					style={{ marginTop: "0" }}
				>
					<Grid item xs={12}>
						<Grid
							container
							direction="column"
							justifyContent="center"
							style={{
								backgroundColor: uiType != 1 ? theme.palette.secondary.main : '#ffffff',
								padding: '10%',
							}}
						>
							<Grid item>
								<Typography
									data-testid="confirm-forgot-password-title"
									variant="h1"
									style={{
										color: uiType != 1 ? "#fff" : '#000',
										textAlign: "center",
										fontFamily: "Source Sans Pro",
										fontSize: "5.3rem",
										marginBottom: "67.4px",
									}}
								>
									{intl.formatMessage({
										id: "app.confirmPasswordChange.title",
									})}
								</Typography>
							</Grid>
							<Grid container justifyContent="center">
								<Typography
									data-testid="confirm-forgot-password-success"
									variant="subtitle2"
									style={{
										color: uiType != 1 ? "#fff" : '#000',
										textAlign: "center",
										marginBottom: "2.5rem",
									}}
								>
									{intl.formatMessage({
										id: "app.confirmPasswordChange.success.label",
									})}
								</Typography>
							</Grid>
							<Grid
								item
								style={{
									marginBottom: "1rem",
								}}
							>
								<FormTextField
									data-testid="confirm-forgot-password-email"
									label={intl.formatMessage({
										id: "app.confirmPasswordChange.email",
									})}
									fullWidth
									type="text"
									name="email"
									margin="normal"
									variant="filled"
									disabled={isEmailPrepopulated}
									inputLabelProps={{
										className: classes.input,
									}}
									inputProps={{
										className: classes.input,
									}}
									validators={[requiredValidator, emailValidator]}
								/>
							</Grid>
							<Grid
								item
								style={{
									marginBottom: "1rem",
								}}
							>
								<FormTextField
									data-testid="confirm-forgot-password-token"
									label={intl.formatMessage({
										id: "app.confirmPasswordChange.token",
									})}
									fullWidth
									type="number"
									name="code"
									margin="normal"
									variant="filled"
									disabled={isCodePrepopulated}
									inputLabelProps={{
										className: classes.input,
									}}
									inputProps={{
										className: classes.input,
									}}
									validators={[requiredValidator]}
								/>
							</Grid>
							<Grid
								item
								style={{
									marginBottom: "1rem",
								}}
							>
								<FormPassword
									data-testid="confirm-forgot-password-password"
									label={intl.formatMessage({
										id: "app.confirmPasswordChange.password",
									})}
									fullWidth
									name="password"
									margin="normal"
									variant="filled"
									inputLabelProps={{
										className: classes.input,
									}}
									inputProps={{
										className: classes.input,
									}}
									maxLength={99}
									validators={[requiredValidator, passwordStrengthValidator]}
								/>
							</Grid>
							<Grid
								item
								style={{
									marginBottom: "10px",
								}}
							>
								<FormPassword
									data-testid="confirm-forgot-password-reenter"
									label={intl.formatMessage({
										id: "app.confirmPasswordChange.confirmPassword",
									})}
									fullWidth
									name="confirmPassword"
									margin="normal"
									variant="filled"
									inputLabelProps={{
										className: classes.input,
									}}
									inputProps={{
										className: classes.input,
									}}
									maxLength={99}
									validators={[required, passwordMatchValidator]}
								/>
							</Grid>
							<Grid
								item
								style={{
									marginBottom: "50px",
								}}
							>
								<Link
									data-testid="confirm-forgot-password-link-signin"
									to={`/auth/login?ui=${uiType}`}
									className={classes.returnBackLink}
									component={RouterLink}
                                    color="secondary"
								>
									{intl.formatMessage({
										id: "app.confirmPasswordChange.signinLinkText",
									})}
								</Link>
							</Grid>
							<Grid
								item
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<Button
									data-testid="confirm-forgot-password-button-submit"
									variant="contained"
									color="primary"
									type="submit"
								>
									{intl.formatMessage({
										id: "app.confirmPasswordChange.reset",
									})}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
		</form>  
	  </WelcomeLayout>


	);
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	changeCode: (code) => dispatch(change('forgotpassword', 'code', code)),
	changeEmail: (email) => dispatch(change('forgotpassword', 'email', email)),
	submitRequest: (email, code, password, uiType) => dispatch(confirmForgotPasswordEmail(email, code, password, uiType))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'forgotpassword',
		touchOnChange: true,
		touchOnBlur: true
	}),
	withRouter
)

export default enhance(ConfirmForgotPassword)

import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import WelcomeText from './welcomeText';
import emStyleLogo from '../../assets/login/EmStyleOutcomesRegistry-side-box.png'

const useStyles = ((uiType) => {
	return makeStyles((theme) => ({
	pageLogin: {
		flexWrap: 'wrap',
		flexDirection: 'column',
		minHeight: '100vh',
		[theme.breakpoints.up('md')]: {
			justifyContent: 'center',
			flexDirection: 'row',
		},
	},
	gridItemText: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.up('md')]: {
			marginBottom: '40px',
			flexDirection: 'column',
			justifyContent: 'center',
			width: 'auto',
			padding: '30px !important',
			minHeight: 'calc(100vh - 60px)',
		},
	},
	gridItem: {
		padding: '30px !important',
		width: '100%',
		minHeight: 'unset',
		backgroundColor: uiType != 1 ? theme.palette.secondary.main : 'white',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexGrow: '100',
		[theme.breakpoints.up('md')]: {
			minHeight: '100vh',
			width: 'auto',

		},

	},
	pageLoginForm: {
		position: 'relative',
		zIndex: 1,
		width: '100%',
		marginLeft: 'auto !important',
		marginRight: 'auto !important',
		minHeight: '496px',
		maxWidth: '560px',
		padding: '30px',
		[theme.breakpoints.down('md')]: {
			minHeight: 'unset',
		},
	},
}))();
})
const WelcomeLayout = ({ intl, headerTextId, welcomeMessageId, contactUsId, contactUsLinkId, children, uiType }) => {
	const classes = useStyles(uiType);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Grid
			container
			direction="row"
			className={classes.pageLogin}
		>
			{
				uiType != 1 &&
				<Grid item sm={12} md={6} className={classes.gridItemText}>
					<WelcomeText
						intl={intl}
						headerTextId={headerTextId}
						welcomeMessageId={welcomeMessageId}
						contactUsId={contactUsId}
						contactUsLinkId={contactUsLinkId}
					/>
				</Grid>
			}
			{
				uiType == 1 &&
				<Grid item sm={12} md={6}
					data-testid="emstyle-logo"					
					style={{
						backgroundImage: `url(${emStyleLogo})`,
						backgroundPosition: 'center', 
						backgroundRepeat: 'no-repeat', 
						backgroundSize: 'contain',
						backgroundColor: '#2267AD'
					}}
				>
				</Grid>
			}
			<Grid item sm={12} md={6} className={`${classes.gridItem}`}>
				<div className={classes.pageLoginForm}>
					{children}
				</div>
			</Grid>
		</Grid>
	);
};

WelcomeLayout.propTypes = {
	intl: PropTypes.object.isRequired,
	headerTextId: PropTypes.string.isRequired,
	welcomeMessageId: PropTypes.string.isRequired,
	contactUsId: PropTypes.string.isRequired,
	contactUsLinkId: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	uiType: PropTypes.number
};

export default WelcomeLayout;

import React, { useEffect, useState } from 'react'
import { useTheme, withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router'
import { requestForgotPasswordEmail } from '../../redux/actions'
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { connect } from 'react-redux'
import FormTextField from '../../components/FormTextField'
import { reduxForm } from 'redux-form'
import { required, email } from '../../utilities/validators'
import '../../styles/index.scss'
import { injectIntl } from 'react-intl'
import WelcomeLayout from "../welcome/welcomeLayout";
import queryString from "query-string";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {},
	input: {
		color: "#fff",
		fontSize: "1.7rem",
	},
	returnBackLink: (props) => ({
		textDecoration: 'none',
		fontSize: '13px',
		fontWeight: '400',
		textTransform: 'uppercase',
		display: 'table',
		letterSpacing: '1.28px',
		margin: '0 0 20px',
		color: props.uiType != 1 ? '#fff' : '#000',
		'&:hover, &:focus': {
		  color: props.uiType != 1 ? '#fff' : '#000',
		  textDecoration: 'none',
		},
	  }),
}));

const ForgotPassword = ({ history, submitRequest, handleSubmit, pristine, reset, submitting, intl, location }) => {
	const theme = useTheme()
	const [ uiType, setUiType ] = useState(0)

	const classes = useStyles({ uiType });

	const onSubmitForm = (values) => {
		submitRequest(values.email, uiType)
	}

	useEffect(() => {
		let params = queryString.parse(location.search)

		if (params && params.ui) {
			setUiType(Number(params.ui));
		}
	}, [])


	return (
		<WelcomeLayout
			intl={intl}
			headerTextId="app.login.headerText"
			welcomeMessageId="app.login.welcomeMessageText"
			contactUsId="app.login.contactUsText"
			contactUsLinkId="app.login.contactUsLinkText"
			uiType={uiType}
		>
			<form
				onSubmit={handleSubmit(onSubmitForm)}
				style={{ width: "100%" }}
			>
				<Grid
					item
					style={{
						width: "100%",
					}}
				>
					{
						uiType != 1 && 
						<Typography
							data-testid="incytes-logo-title"
							style={{
								color: "#fff",
								textAlign: "center",
								marginBottom: "20px",
							}}
						>
							<span
								style={{
									fontSize: "clamp(37px, 2vw, 56px)",
									lineHeight: "1.6",
									fontWeight: "600",
									position: "relative",
								}}
							>
								inCytes
								<span
									style={{
										fontSize: "16px",
										position: "absolute",
										right: "-11px",
										top: "6px",
									}}
								>
									™
								</span>
							</span>
						</Typography>
					}
					<Typography
						variant="h1"
						style={{
							color: uiType != 1 ? "#fff" : '#000',
							textAlign: "center",
							fontSize: "clamp(30px, 1.4vw, 48px)",
							marginBottom: "40px",
							lineHeight: "1.4",
						}}
					>
						{intl.formatMessage({ id: "app.forgotPass.title" })}
					</Typography>

					<Grid
						item
						style={{
							width: "100%",
							paddingBottom: "10px",
						}}
						className="narrow-input-wrapper"
					>
						<FormTextField
							label={intl.formatMessage({ id: "app.login.email" })}
							fullWidth
							type="text"
							name="email"
							validators={[required, email]}
							variant="filled"
						/>
					</Grid>
					<Grid
						item
						style={{
							width: "100%",
						}}
					>
						<Link
							to={`/auth/login?ui=${uiType}`}
							className={classes.returnBackLink}
							component={RouterLink}
							color="secondary"
						>
							{intl.formatMessage({ id: "app.forgotPass.backLabel" })}
						</Link>
					</Grid>
					<Grid item style={{ width: "100%" }}>
						<Grid
							container
							direction="row"
							justifyContent="center"
							alignItems="center"
							style={{ width: "calc(100% + 20px)", margin: "0 -10px" }}
						>
							<Grid item style={{ margin: "10px" }}>
								{/* <Button
												component={RouterLink}
												to="/auth/register"
												color="primary"
												variant="outlined"
											>
												{intl.formatMessage({
													id: "app.forgotPass.signUpButton",
												})}
											</Button> */}
								<a href="https://www.rgnmed.com/support/request-a-demo">
									<Button
										color="primary"
										variant="outlined"
									>
										{intl.formatMessage({
											id: "app.forgotPass.signUpButton",
										})}
									</Button>
								</a>
							</Grid>

							<Grid item style={{ margin: "10px" }}>
								<Button
									label="Password"
									variant="contained"
									color="primary"
									type="submit"
								>
									{intl.formatMessage({
										id: "app.forgotPass.submitButton",
									})}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</WelcomeLayout>

	)
}

const mapDispatchToProps = (dispatch, ownProps) => ({
	submitRequest: (email, uiType) => dispatch(requestForgotPasswordEmail(email, uiType))
})

const enhance = compose(
	connect(null, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'login'
	}),
	withRouter
)

export default enhance(ForgotPassword)

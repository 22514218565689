import React, { useState, useEffect } from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { withRouter, Link } from "react-router-dom";

import FormTextField from "../../components/FormTextField";
import { connect } from "react-redux";
import {
	fetchJurisdictionsByLocale,
	getUserRegistrationData,
	getIsUserRegistered,
	registerUser,
	setIntlLocale,
} from "../../redux/actions";
import { reduxForm, change, formValueSelector } from "redux-form";
import { required, passwordMatch, passwordStrength, email } from "../../utilities/validators";

import translations from "../../utilities/languages/locales";
import { injectIntl } from "react-intl";
import MobileDetect from "mobile-detect";
import queryString from "query-string";
import FormCheckBox from "./components/FormCheckbox";
import Container from "@material-ui/core/Container";
import { isMobile } from "./../../utilities/mobileDetector";
import JurisdictionSelector from "../../components/JurisdictionSelector";
import {
	isRegisteringSelector,
	jurisdictionsSelector,
	userRegistrationDataSelector,
	isUserRegisteredSelector,
	isLoadingUsersSelector,
} from "../../redux/selectors";
import locale2 from "locale2";
import { CircularProgress } from "@material-ui/core";
import FormPassword from "../../components/FormPassword";
import WelcomeLayout from "../welcome/welcomeLayout";

const styles = {};

const requiredValidator = required;
const passwordStrengthValidator = passwordStrength;
const passwordMatchValidator = passwordMatch("password");
const selector = formValueSelector("registration");
const Registration = ({
	history,
	login,
	register,
	handleSubmit,
	createFailed,
	intl,
	location,
	setEmail,
	setRegion,
	setLanguage,
	setLocale,
	isTermsAccepted,
	loadJurisdictions,
	jurisdictions,
	match,
	getUserRegistrationData,
	getIsUserRegistered,
	isSaving,
	userData,
	isUserRegistered,
	setFirstName,
	setLastName,
	isLoading,
}) => {
	const theme = useTheme();

	let params = queryString.parse(location.search);
	const [isEmailPrepopulated, setIsEmailPrepopulated] = useState(false);
	const [isVerified, setIsVerified] = useState(false);
	const [isRegionPrepopulated, setIsRegionPrepopulated] = useState(false);
	const [uiType, setUiType ] = useState(params.ui)
	const [loginLink, setLoginLink] = useState("/auth/login?ui=" + uiType);
	const [isAllowed, setIsAllowed] = useState(false);

	const onSubmitForm = (values) => {
		let params = queryString.parse(location.search);
		if (params && params.autoInvite) {
			values.autoInvite = params.autoInvite;
		}
		if (params && params.token) {
			values.verificationToken = params.token;
		}

		values.timeZoneOffset = -(new Date().getTimezoneOffset() / 60);
		register(values, isEmailPrepopulated && isVerified, uiType);
	};

	useEffect(() => {
		let params = queryString.parse(location.search);

		if (params && params.email) {
			setEmail(params.email);

			getIsUserRegistered(params.email);

			setIsEmailPrepopulated(true);
			getUserRegistrationData(params.email);
		}

		if (params && params.token) {
			setIsVerified(true);
			setIsAllowed(true);
		}

		if (params && params.region) {
			setRegion(params.region);
			setIsRegionPrepopulated(true);
		}

		if (params && params.ui) {
			setUiType(params.ui)
			setLoginLink("/auth/login?ui=" + uiType);
		}

		if (params && params.autoInvite) {
			setLoginLink(
				`/auth/login?ui=${params.ui}&returnUrl=/circleinvitation?data=${params.autoInvite}`
			);
			setIsAllowed(true);
		}

		let locale = intl.locale;

		if (locale2) {
			locale = locale2.substring(0, 5);
			if (locale !== "en-GB") {
				locale = locale2.substring(0, 2);
			}
		}

		if (!translations[locale]) {
			locale = "en";
		}

		setLanguage(params && params.language ? params.language : locale);

		//setLocale(params.language)

		loadJurisdictions(locale);
	}, []);

	useEffect(() => {
		if (isUserRegistered) {
			history.push("/auth/login?ui=" + uiType);
		}
	}, [isUserRegistered]);

	useEffect(() => {
		if (userData) {
			setLocale(userData.languageAbbreviation);
			setFirstName(userData.firstName);
			setLastName(userData.lastName);
		}
	}, [userData]);

	return (
		isAllowed &&
		!isLoading && (

			<WelcomeLayout
				intl={intl}
				headerTextId="app.login.headerText"
				welcomeMessageId="app.login.welcomeMessageText"
				contactUsId="app.login.contactUsText"
				contactUsLinkId="app.login.contactUsLinkText"
				uiType={uiType}
			>
				<form
					data-testid="form-registration"
					onSubmit={handleSubmit(onSubmitForm)}
					style={{ width: "100%" }}
				>
					<input id="language" name="language" type="hidden" />
					<Grid item>
						{
							uiType != 1 && 
							<Typography
								data-testid="incytes-logo-title"
								style={{
									color: "#fff",
									textAlign: "center",
									marginBottom: "20px",
								}}
							>
								<span
									style={{
										fontSize: "clamp(37px, 2vw, 56px)",
										lineHeight: "1.6",
										fontWeight: "600",
										position: "relative",
									}}
								>
									inCytes
									<span
										style={{
											fontSize: "16px",
											position: "absolute",
											right: "-11px",
											top: "6px",
										}}
									>
										™
									</span>
								</span>
							</Typography>
						}
						<Typography
							data-testid="form-registration-title"
							variant="h1"
							style={{
								color: uiType != 1 ? "#fff" : '#000',
								textAlign: "center",
								fontSize: "clamp(27px, 1.4vw, 48px)",
								marginBottom: "20px",
								lineHeight: "1.4",
							}}
						>
							{intl.formatMessage({ id: "app.registration.title" })}
						</Typography>
					</Grid>

					<Grid item style={{ width: "100%" }}>
						{createFailed && (
							<div
								style={{
									backgroundColor: "#b00020",
									borderRadius: "3px",
								}}
							>
								<Typography
									data-testid="form-registration-error-message"
									variant="body2"
									style={{
										color: "#fff",
										textAlign: "left",
										fontWeight: "400",
										margin: "0 0 40px",
									}}
								>
									{intl.formatMessage({
										id: "app.errorMessages.2",
									})}
								</Typography>
							</div>
						)}
					</Grid>
					<Grid className="narrow-input-wrapper" item style={{ width: "100%" }}>
						<FormTextField
							data-testid="form-registration-first-name"
							label={intl.formatMessage({
								id: "app.registration.firstName",
							})}
							fullWidth
							type="text"
							name="firstName"
							variant="filled"
							maxLength={255}
							validators={[requiredValidator]}
							required
						/>
					</Grid>
					<Grid className="narrow-input-wrapper" item style={{ width: "100%" }}>
						<FormTextField
							data-testid="form-registration-last-name"
							label={intl.formatMessage({
								id: "app.registration.lastName",
							})}
							fullWidth
							type="text"
							name="lastName"
							variant="filled"
							maxLength={255}
							validators={[requiredValidator]}
							required
						/>
					</Grid>
					<Grid className="narrow-input-wrapper" item style={{ width: "100%" }}>
						<FormTextField
							data-testid="form-registration-email"
							label={intl.formatMessage({
								id: "app.registration.email",
							})}
							fullWidth
							type="email"
							name="email"
							variant="filled"
							disabled={isEmailPrepopulated}
							maxLength={255}
							validators={[requiredValidator, email]}
							required
						/>
					</Grid>
					{!isRegionPrepopulated && (
						<Grid className="narrow-input-wrapper" item style={{ width: "100%" }}>
							<Typography
								data-testid="form-registration-help-text"
								style={{ 
									color: uiType != 1 ? "#fff" : '#000',
									fontSize: '13px', 
									lineHeight: '1.2', 
									marginBottom: '5px' }}
							>
								{intl.formatMessage({
									id: "app.registration.piRegionHelpText",
								})}
							</Typography>
							<JurisdictionSelector
								data-testid="form-registration-jurisdiction"
								label={intl.formatMessage({
									id: "app.general.jurisdictionLabel",
								})}
								fullWidth
								data={jurisdictions}
								name="jurisdictionId"
								variant="filled"
								validators={[requiredValidator]}
								required
							/>
						</Grid>
					)}
					<Grid item style={{ width: "100%" }}>
						<Typography
							data-testid="form-registration-help-text"
							style={{ 
								color: uiType != 1 ? "#fff" : '#000',
								fontSize: '13px', 
								lineHeight: '1.2', 
								marginBottom: '5px' }}
						>
							{intl.formatMessage({
								id: "app.registration.passwordHelpText",
							})}
						</Typography>
						<div className="narrow-input-wrapper">
							<FormPassword
								data-testid="form-registration-password"
								label={intl.formatMessage({
									id: "app.registration.password",
								})}
								fullWidth
								name="password"
								variant="filled"
								maxLength={99}
								validators={[
									requiredValidator,
									passwordStrengthValidator,
								]}
								required
							/>
						</div>
					</Grid>
					<Grid className="narrow-input-wrapper" item style={{ width: "100%" }}>
						<FormPassword
							data-testid="form-registration-reentee"
							label={intl.formatMessage({
								id: "app.registration.confirmPassword",
							})}
							fullWidth
							name="verifyPassword"
							variant="filled"
							maxLength={99}
							validators={[
								requiredValidator,
								passwordMatchValidator,
							]}
							required
						/>
					</Grid>
					<Grid
						item
						style={{ width: "100%", paddingBottom: "2rem" }}
					>
						<FormCheckBox
							data-testid="form-registration-terms"
							style={{ color: "#fff" }}
							name="terms"
							validators={[requiredValidator]}
							label={
								<Typography
									style={{ 
										color: uiType != 1 ? "#fff" : '#000',
										fontSize: '14px',
										lineHeight: '1.2' }}
								>
									{intl.formatMessage(
										{ id: "app.registation.grantConsentText" },
										{
											link: (
												<a
													href="https://kb.rgnmed.com/hubfs/LegalDocuments/inCytes-License-Terms-and-Conditions-2024.pdf"
													target="_blank"
													style={{ color: uiType != 1 ? "#fff" : '#000', }}
												>
													{intl.formatMessage({
														id: "app.registation.termsLinkText",
													})}
												</a>
											),
										}
									)}
								</Typography>
							}
						/>
					</Grid>
					<Grid item style={{ width: "100%" }}>
						<Grid
							container
							direction="column"
							alignItems="center"
							justifyContent="center"
							style={{
								width: '70%',
								margin: '0 auto'
							}}
						>
							{isSaving && (
								<Grid item style={{ textAlign: "center" }}>
									<CircularProgress />
								</Grid>
							)}
							{!isSaving && (
								<Button
									data-testid="form-registration-button-submit"
									variant="contained"
									color="primary"
									style={{
										margin: "0 0 25px",
									}}
									disabled={!isTermsAccepted}
									fullWidth
									type="submit"
									size="small"
								>
									{intl.formatMessage({
										id: "app.registration.next",
									})}
								</Button>
							)}
							<Button
								data-testid="form-registration-button-back"
								component={Link}
								to={loginLink}
								color="primary"
								variant="outlined"
								size="small"
								fullWidth
								style={{
									margin: "0 0 25px",
									color: uiType != 1 ? "#fff" : '#000',
								}}
							>
								{intl.formatMessage({
									id: "app.registration.backLinkText",
								})}
							</Button>
						</Grid>
					</Grid>

				</form>
			</WelcomeLayout>
		)
	);
};

const mapStateToProps = (state, ownProps) => ({
	isTermsAccepted: selector(state, "terms"),
	jurisdictions: jurisdictionsSelector(state),
	userData: userRegistrationDataSelector(state),
	isUserRegistered: isUserRegisteredSelector(state),
	isSaving: isRegisteringSelector(state),
	isLoading: isLoadingUsersSelector(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	setEmail: (email) => dispatch(change("registration", "email", email)),
	setRegion: (region) =>
		dispatch(change("registration", "jurisdictionId", region)),
	setLanguage: (language) =>
		dispatch(change("registration", "language", language)),
	setFirstName: (value) => dispatch(change("registration", "firstName", value)),
	setLastName: (value) => dispatch(change("registration", "lastName", value)),
	setLocale: (locale) => dispatch(setIntlLocale(locale)),
	register: (values, emailVerified, uiType) =>
		dispatch(registerUser(values, emailVerified, uiType)),
	loadJurisdictions: (locale) => dispatch(fetchJurisdictionsByLocale(locale)),
	getUserRegistrationData: (email) => dispatch(getUserRegistrationData(email)),
	getIsUserRegistered: (email) => dispatch(getIsUserRegistered(email)),
});

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: "registration",
	}),
	withRouter,
	injectIntl,
	withStyles(styles)
);

export default enhance(Registration);
